import { FC } from 'react'
import cx from 'classnames'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { ChevronIcon } from 'icons'

export interface IDropdownProps {
  title: string
  value: string
  items: { label: string; value: string }[]
  onSelect: (value: string) => void
  align?: 'start' | 'center' | 'end'
  modal?: boolean
}

export const Dropdown: FC<IDropdownProps> = ({
  title,
  items,
  value,
  onSelect,
  align = 'center',
  modal = true,
}) => {
  const activeLabel = items.find((item) => item.value === value)

  return (
    <DropdownMenu.Root modal={modal}>
      <DropdownMenu.Trigger className="flex items-center overflow-hidden rounded-sm bg-white p-10 ring-1 ring-grey-3 state-open:ring-2 state-open:ring-teal-3">
        <p className="text-16">
          <span className="font-semibold">{title}</span>
          <span className="ml-4">{activeLabel?.label ?? ''}</span>
        </p>
        <div className="ml-12 flex items-center">
          <ChevronIcon className="stroke-grey-6 rect-22" />
        </div>
      </DropdownMenu.Trigger>
      <DropdownMenu.Content
        align={align}
        className="rounded-md border border-green-1 bg-white p-20 shadow-2xl"
        sideOffset={12}
      >
        {items.map((item) => (
          <DropdownMenu.Item
            key={item.value}
            onClick={() => onSelect(item.value)}
            className={cx('cursor-pointer p-4 text-14 hover:text-teal-3', {
              'text-teal-3': item.value === value,
            })}
          >
            {item.label}
          </DropdownMenu.Item>
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  )
}
