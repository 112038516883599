import { FC } from 'react'
import cx from 'classnames'
import * as Tooltip from '@radix-ui/react-tooltip'
import { Side } from '@radix-ui/popper/dist/index'

import { InformationIcon } from 'icons'
import { TooltipContent } from 'ui'

export const InfoTooltip: FC<{ className?: string; side?: Side }> = ({
  children,
  className,
  side,
}) => {
  return (
    <Tooltip.Root delayDuration={500}>
      <Tooltip.Trigger asChild>
        <button className={cx(className, 'rounded-full')} type="button">
          <span className="sr-only">Information</span>
          <InformationIcon
            className="stroke-grey-6 rect-12"
            aria-hidden="true"
          />
        </button>
      </Tooltip.Trigger>
      <TooltipContent side={side} className="max-w-280">
        {children}
      </TooltipContent>
    </Tooltip.Root>
  )
}
