import React, { HTMLProps, forwardRef } from 'react'
import cx from 'classnames'

import { FormLabel, HelperText, ConditionalWrapper, InfoTooltip } from 'ui'

export interface IInputProps extends HTMLProps<HTMLInputElement> {
  'data-testid'?: string
  customOnChange?: (id: string, val: string) => void
  error?: boolean
  helperText?: React.ReactNode
  setHelperTextAbsolute?: boolean
  hideLabel?: boolean
  id: string
  label?: string
  tooltip?: string
  srLabel?: string
  inputClassName?: string
  inputWrapperClassName?: string
  removeFocusStyles?: boolean
}

export const Input = forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      'data-testid': dataTestId,
      className,
      inputClassName,
      inputWrapperClassName,
      error,
      helperText,
      setHelperTextAbsolute, // assumes 1 line
      hideLabel,
      id,
      name,
      label,
      srLabel,
      tooltip,
      customOnChange,
      placeholder,
      required,
      disabled,
      type = 'text',
      onChange,
      onFocus,
      onBlur,
      value,
      role,
      inputMode,
      removeFocusStyles,
    },
    ref
  ) => {
    return (
      <div
        className={cx(className, {
          'relative pb-24': setHelperTextAbsolute,
        })}
      >
        {label ? (
          <FormLabel
            className={cx({
              'sr-only': hideLabel,
            })}
            required={required}
            htmlFor={id}
          >
            {label}
            {srLabel ? <span className="sr-only"> {srLabel}</span> : null}
            {tooltip ? (
              <InfoTooltip className="ml-8">
                Yearly appreciation over the 3-year-term. Enter a negative value
                like “-3” to see deprecation.
              </InfoTooltip>
            ) : null}
          </FormLabel>
        ) : null}
        <ConditionalWrapper
          condition={!!inputWrapperClassName}
          wrapper={(children) => (
            <div className={inputWrapperClassName}>{children}</div>
          )}
        >
          <input
            ref={ref}
            className={cx(
              inputClassName,
              'block h-40 w-full appearance-none rounded-sm border border-grey-6 bg-white px-10 py-8 leading-tight tracking-2 transition-all',
              'placeholder:text-grey-5',
              'disabled:bg-grey-3',
              'hover:border-teal-3',
              {
                'focus:border-teal-3 focus:text-grey-8 focus:ring-1 focus:ring-inset focus:ring-teal-3':
                  !removeFocusStyles,
                'border-error-1 text-error-1': error,
                'mt-4': !hideLabel,
              }
            )}
            data-testid={dataTestId}
            disabled={disabled}
            id={id}
            name={name}
            onChange={(e) => {
              if (onChange) {
                onChange(e)
              } else if (customOnChange) {
                customOnChange(id, e.target.value)
              }
            }}
            onFocus={onFocus}
            onBlur={onBlur}
            placeholder={placeholder}
            required={required}
            type={type}
            value={value}
            role={role}
            inputMode={inputMode}
          />
        </ConditionalWrapper>
        {helperText ? (
          <HelperText
            className={cx('mt-4 text-left', {
              'absolute bottom-0 left-0 w-full': setHelperTextAbsolute,
            })}
            error={error}
          >
            {helperText}
          </HelperText>
        ) : null}
      </div>
    )
  }
)

Input.displayName = 'Input'
