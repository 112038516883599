import React from 'react'
import cx from 'classnames'

type IFormLabelProps = {
  className?: string
  htmlFor?: string
  required?: boolean
  id?: string
  ariaHidden?: boolean
}

export const FormLabel: React.FunctionComponent<IFormLabelProps> = ({
  children,
  className,
  htmlFor,
  required,
  id,
  ariaHidden,
}) => {
  return (
    <label
      id={id}
      htmlFor={htmlFor}
      className={cx(className, 'flex font-semibold leading-tight')}
      aria-hidden={ariaHidden}
    >
      {children}
      {required && <span className="ml-4 align-text-top text-teal-3">*</span>}
    </label>
  )
}
