import { FC, HTMLProps } from 'react'
import cx from 'classnames'

import { FormLabel } from 'ui'

export interface ITextareaProps extends HTMLProps<HTMLTextAreaElement> {
  id: string
}

export const Textarea: FC<ITextareaProps> = ({
  className,
  name,
  id,
  placeholder,
  onChange,
  value,
  label,
  required,
  rows = 4,
  maxLength,
}) => {
  const entryLength = value?.toString().length ?? 0
  return (
    <div
      className={cx(className, {
        relative: typeof maxLength !== 'undefined',
      })}
    >
      <FormLabel htmlFor={id} required={required}>
        {label}
      </FormLabel>
      <label htmlFor={id}></label>
      <textarea
        name={name || id}
        id={id}
        rows={rows}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        maxLength={maxLength}
        className={cx(
          'mt-4 block w-full resize-none appearance-none px-10 py-8',
          'rounded-xs border border-grey-6 bg-white leading-tight tracking-2 transition-all',
          'placeholder:text-grey-6',
          'disabled:bg-grey-3',
          'hover:border-teal-3',
          'focus:border-teal-3 focus:text-grey-8 focus:ring-1 focus:ring-inset focus:ring-teal-3'
        )}
      />
      {typeof maxLength !== 'undefined' ? (
        <span
          className={cx('group absolute bottom-0 right-0', {
            'text-grey-6': entryLength < maxLength,
            'font-semibold text-grey-8': entryLength === maxLength,
          })}
        >
          <span className="m-4 block bg-white bg-opacity-90 px-8 py-4 transition-transform duration-200 util-caption group-hover:translate-y-full">
            {entryLength}/{maxLength}
            <span className="sr-only">characters</span>
          </span>
        </span>
      ) : null}
    </div>
  )
}
