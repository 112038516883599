import { FC, HTMLProps } from 'react'
import cx from 'classnames'

import { HelperText } from 'ui'

import { CheckIcon } from 'icons'

export interface ICheckboxProps extends HTMLProps<HTMLInputElement> {
  'data-testid'?: string
  customOnChange?: (id: string, value: boolean) => void
  error?: boolean
  helperText?: React.ReactNode
  id: string
  labelRegular?: boolean
  hideAsterisk?: boolean
}

export const Checkbox: FC<ICheckboxProps> = ({
  'data-testid': dataTestId,
  checked,
  className,
  customOnChange,
  disabled,
  error,
  helperText,
  id,
  label,
  labelRegular,
  name,
  onChange,
  required,
  hideAsterisk,
}) => {
  return (
    <div className={className}>
      <input
        className="sr-only"
        checked={checked}
        data-testid={dataTestId}
        disabled={disabled}
        required={required}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          } else if (customOnChange) {
            customOnChange(id, e.target.checked)
          }
        }}
        type="checkbox"
        name={name || id}
        id={id}
      />
      <label
        className={cx('flex items-start space-x-8 leading-tight', {
          'text-error-1': error,
          'font-semibold': !labelRegular,
        })}
        htmlFor={id}
      >
        <span
          className={cx(
            'checkbox',
            'grid shrink-0 items-center justify-center rect-18',
            'cursor-point rounded-xs border bg-white transition-colors',
            'hover:bg-teal-1',
            {
              'border-grey-6': !error,
              'border-error-1': error,
            }
          )}
        >
          <CheckIcon className="hidden rect-12" />
        </span>
        <span className="-mt-4 flex body">
          {label}{' '}
          {required && !hideAsterisk ? (
            <span className="ml-4 align-text-top text-teal-3">*</span>
          ) : null}
        </span>
      </label>
      {helperText ? (
        <HelperText className="mt-4" error={error}>
          {helperText}
        </HelperText>
      ) : null}
    </div>
  )
}
