import { FC } from 'react'
import cx from 'classnames'
import * as Slider from '@radix-ui/react-slider'

export const SliderTrack: FC<{
  size?: 'small' | 'large'
}> = ({ children, size = 'small' }) => {
  return (
    <Slider.Track
      className={cx(
        'relative w-full overflow-hidden rounded-[32px] bg-green-1',
        {
          'h-6': size === 'small',
          'h-12': size === 'large',
        }
      )}
    >
      {children}
    </Slider.Track>
  )
}

export const SliderRange = ({
  className,
}: {
  className?: string
  invert?: boolean
}) => {
  return (
    <Slider.Range
      className={cx(
        className,
        'absolute h-full bg-teal-3 data-disabled:bg-grey-5'
      )}
    />
  )
}

export const SliderThumb: FC<{
  size?: 'small' | 'large'
  className?: string
  tabIndex?: number
  ariaValueText?: string
  ariaLabelledby?: string
}> = ({
  children,
  size = 'small',
  className,
  tabIndex = 0,
  ariaValueText,
  ariaLabelledby,
}) => {
  return (
    <Slider.Thumb
      aria-valuetext={ariaValueText}
      aria-labelledby={ariaLabelledby}
      className={cx(
        className,
        'relative block h-[22px] w-[22px] cursor-grab rounded-full border border-teal-3 bg-white shadow-md active:cursor-grabbing',
        'after:absolute after:inset-0 after:scale-[0.65] after:rounded-full after:bg-teal-3',
        'data-disabled:border-grey-5 data-disabled:after:bg-grey-5',
        {
          '-top-8': size === 'small',
          '-top-5': size === 'large',
        }
      )}
      tabIndex={tabIndex}
    >
      {children ? (
        <div
          className={cx(
            'absolute left-1/2 top-32 -translate-x-1/2 rounded-sm bg-black px-8 py-6 font-semibold text-white',
            'after:absolute after:bottom-full after:left-[calc(50%-5px)] after:h-6 after:w-[10px] after:bg-black after:[clip-path:polygon(50%_0,0_100%,100%_100%)]'
          )}
        >
          {children}
        </div>
      ) : null}
    </Slider.Thumb>
  )
}
