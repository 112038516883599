import React, { HTMLProps } from 'react'
import cx from 'classnames'
import * as Select from '@radix-ui/react-select'

import { FormLabel, HelperText } from 'ui'

import { ChevronIcon, CheckIcon } from 'icons'

export interface IFancySelectProps
  extends Omit<HTMLProps<HTMLSelectElement>, 'onChange'> {
  'data-testid'?: string
  onChange?: (id: string, value: string) => void
  items: { title: string; value: string }[]
  label: string
  error?: boolean
  helperText?: React.ReactNode
  hideLabel?: boolean
  id: string
}

export const FancySelect: React.FunctionComponent<IFancySelectProps> = ({
  'data-testid': dataTestId,
  className,
  onChange,
  disabled,
  error,
  helperText,
  hideLabel,
  id,
  name,
  items,
  label,
  required,
  placeholder,
  value,
}) => {
  return (
    <div className={className}>
      <FormLabel
        className={cx({
          'sr-only': hideLabel,
        })}
        required={required}
        id={`label-${id}`}
        ariaHidden
      >
        {label}
      </FormLabel>
      <Select.Root
        data-testid={dataTestId}
        name={name}
        onValueChange={(value) => {
          onChange ? onChange(id, value) : null
        }}
        value={value?.toString()}
        defaultValue={value !== undefined ? undefined : ''}
        required={required}
      >
        <Select.Trigger
          className={cx(
            'relative mt-4 w-full rounded-sm border border-grey-6 bg-white py-8 pl-10 pr-40 text-left leading-tight tracking-2 shadow-[0px_1px_4px_#dee4e4]',
            'focus:border-teal-3 focus:text-grey-8 focus:ring-1 focus:ring-inset focus:ring-teal-3',
            {
              'pointer-events-none bg-grey-3': disabled,
              'border-error-1 text-error-1': error,
            }
          )}
          aria-label={label}
          aria-labelledby={`label-${id}`}
        >
          <Select.Value />

          <Select.Icon>
            <ChevronIcon
              className={cx(
                'pointer-events-none absolute right-10 top-1/2 -translate-y-1/2 rect-22',
                {
                  'stroke-grey-8': !error,
                  'stroke-error-1': error,
                }
              )}
            />
          </Select.Icon>
        </Select.Trigger>
        <Select.Content className="z-popover overflow-hidden rounded-sm border border-grey-3 bg-white shadow-md">
          <Select.ScrollUpButton className="absolute inset-x-2 top-px z-20 rounded-sm bg-white">
            <ChevronIcon className="mx-auto rotate-180 stroke-grey-8 rect-18" />
          </Select.ScrollUpButton>

          <Select.Viewport className="leading-tight tracking-2">
            {placeholder ? (
              <Select.Item
                disabled
                value=""
                className="relative cursor-default py-8 pl-32 pr-8 text-grey-4"
              >
                <Select.ItemText>{placeholder}</Select.ItemText>

                <Select.ItemIndicator className="absolute left-8 top-12">
                  <CheckIcon className="stroke-grey-4 rect-14" />
                </Select.ItemIndicator>
              </Select.Item>
            ) : null}
            {items.map((item) => (
              <Select.Item
                key={item.value}
                value={item.value}
                className={cx(
                  'relative cursor-default py-8 pl-32 pr-8 hover:bg-grey-2',
                  {
                    'text-teal-3': item.value === value,
                  }
                )}
              >
                <Select.ItemText>{item.title}</Select.ItemText>

                <Select.ItemIndicator className="absolute left-8 top-12">
                  <CheckIcon className="stroke-teal-3 rect-14" />
                </Select.ItemIndicator>
              </Select.Item>
            ))}
          </Select.Viewport>

          <Select.ScrollDownButton className="absolute inset-x-2 bottom-px z-20 rounded-sm bg-white">
            <ChevronIcon className="mx-auto stroke-grey-8 rect-18" />
          </Select.ScrollDownButton>
        </Select.Content>
      </Select.Root>
      {helperText ? (
        <HelperText className="mt-4" error={error}>
          {helperText}
        </HelperText>
      ) : null}
    </div>
  )
}
