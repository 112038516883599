import React, { HTMLProps } from 'react'
import cx from 'classnames'

import { FormLabel, HelperText } from 'ui'

import { ChevronIcon } from 'icons'

export interface ISelectProps extends HTMLProps<HTMLSelectElement> {
  'data-testid'?: string
  customOnChange?: (id: string, value: string) => void
  items: { title: string; value: string }[]
  label: string
  error?: boolean
  helperText?: React.ReactNode
  hideLabel?: boolean
  id: string
}

export const Select: React.FunctionComponent<ISelectProps> = ({
  'data-testid': dataTestId,
  className,
  customOnChange,
  disabled,
  error,
  helperText,
  hideLabel,
  id,
  name,
  items,
  label,
  placeholder,
  required,
  value,
  onChange,
}) => {
  return (
    <div className={className}>
      <FormLabel
        className={cx({
          'sr-only': hideLabel,
        })}
        required={required}
        htmlFor={id}
      >
        {label}
      </FormLabel>
      <div className="relative">
        <select
          data-testid={dataTestId}
          id={id}
          name={name}
          className={cx(
            'mt-4 block h-40 w-full appearance-none rounded-sm border border-grey-6 bg-white py-8 pl-10 pr-40 leading-tight tracking-2 transition-all',
            'disabled:bg-grey-3',
            'hover:border-teal-3',
            'focus:border-teal-3 focus:text-grey-8 focus:ring-1 focus:ring-inset focus:ring-teal-3',
            {
              'border-error-1 text-error-1': error,
            }
          )}
          disabled={disabled}
          onChange={(event) => {
            customOnChange
              ? customOnChange(id, event.target.value)
              : onChange
              ? onChange(event)
              : null
          }}
          required={required}
          value={value}
          defaultValue={value !== undefined ? undefined : ''}
        >
          {placeholder ? (
            <option disabled value="">
              {placeholder}
            </option>
          ) : null}
          {items.map(({ title, value }, index) => (
            <option key={index} value={value}>
              {title}
            </option>
          ))}
        </select>
        <ChevronIcon
          className={cx(
            'pointer-events-none absolute right-10 top-1/2 -translate-y-1/2 rect-22',
            {
              'stroke-grey-8': !error,
              'stroke-error-1': error,
            }
          )}
        />
      </div>
      {helperText ? (
        <HelperText className="mt-4" error={error}>
          {helperText}
        </HelperText>
      ) : null}
    </div>
  )
}
