import cx from 'classnames'
import { useEffect, useState } from 'react'

import { Input } from 'ui'

export type PercentInputProps = {
  className?: string
  id: string
  label: string
  hideLabel?: boolean
  value: number
  setValue: (value: number) => void
  disabled?: boolean
  required?: boolean
  helperText?: string
  floatingHelperText?: boolean
  placeholder?: string
  errorText: string
  setCurrentInputForTracking?: (label: string) => void
}

export const validatePercent = (value: string): boolean => {
  const fractionDigits = value.includes('.')
    ? value.length - 1 - value.indexOf('.')
    : 0

  return value == Number(value).toFixed(fractionDigits)
}

export const PercentInput = ({
  className,
  id,
  label,
  hideLabel,
  value,
  setValue,
  disabled,
  required,
  helperText,
  floatingHelperText,
  errorText,
  placeholder,
  setCurrentInputForTracking,
}: PercentInputProps) => {
  const getValidPercentValueOnly = (value: string): string =>
    value.replace(/[^.0-9]+/g, '')

  const [valueDisplay, setValueDisplay] = useState(`${value}%`)
  const percentValueIsValid = validatePercent(
    getValidPercentValueOnly(valueDisplay)
  )

  useEffect(() => {
    if (validatePercent(`${value}`)) {
      setValueDisplay(`${value}`)
    }
  }, [value])

  return (
    <Input
      className={className}
      disabled={disabled}
      required={required}
      id={id}
      label={label}
      hideLabel={hideLabel}
      value={value ? valueDisplay : ''}
      placeholder={placeholder}
      onFocus={() => {
        if (percentValueIsValid) {
          setValueDisplay(valueDisplay.slice(0, -1))
        } else {
          setValueDisplay(valueDisplay)
        }
      }}
      onBlur={() => {
        if (percentValueIsValid) {
          setValueDisplay(`${valueDisplay}%`)
        } else {
          setValueDisplay(`${valueDisplay}`)
        }
      }}
      error={!percentValueIsValid}
      helperText={
        <span
          className={cx('block', {
            invisible: percentValueIsValid && !helperText,
            'absolute top-full': floatingHelperText,
          })}
        >
          {percentValueIsValid ? helperText ?? errorText : errorText}
        </span>
      }
      onChange={(e) => {
        const newValue = getValidPercentValueOnly(e.currentTarget.value)
        const isValid = validatePercent(newValue)

        if (newValue.startsWith('.')) {
          setValueDisplay(`0.${newValue.slice(1)}`)
        } else {
          setValueDisplay(newValue)
        }

        if (isValid) {
          setValue(Number(newValue))
        }

        if (setCurrentInputForTracking) {
          setCurrentInputForTracking(label)
        }
      }}
      inputMode="decimal"
    />
  )
}
