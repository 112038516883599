import { useState, useEffect } from "react";

export const useLocalStorage = <T>(key: string, defaultValue?: T) => {
  const [value, setValue] = useState(() => {
    let currentValue: T | undefined;

    try {
      const item = localStorage.getItem(key)
      currentValue = item !== null ? JSON.parse(item) : defaultValue;
    } catch (error) {
      currentValue = defaultValue;
    }

    return currentValue;
  });

  useEffect(() => {
    if (value !== undefined) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }, [value, key]);

  return [value, setValue] as const;
};
