import { useLocalStorage } from './use-local-storage'

const PREFERRED_MARKET_LOCAL_STORAGE_KEY = 'preferred-market'

const marketQueryItemRegEx = /^(?<state>[A-Z]{2})\/(?<handle>[a-z]+(?:-[a-z]+)*)$/i

export function usePreferredMarketLocalStorage(initialValue?: string) {
  const [value, setValue] = useLocalStorage(
    PREFERRED_MARKET_LOCAL_STORAGE_KEY,
    initialValue
  )

  return [value !== undefined && marketQueryItemRegEx.exec(value) !== null ? value : undefined, setValue] as const
}
