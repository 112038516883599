import cx from 'classnames'

export const LoadingSpinner = ({
  className,
  colorClassName,
  center = true,
  light,
  'data-testid': dataTestId,
}: {
  className?: string
  colorClassName?: string
  center?: boolean
  light?: boolean
  'data-testid'?: string | null
}) => {
  colorClassName = light ? 'text-white' : colorClassName

  return (
    <div
      className={cx(className, colorClassName, 'loader', {
        'absolute left-1/2 top-1/2 -ml-12 -mt-12': center,
      })}
      data-testid={dataTestId}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
