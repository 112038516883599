import cx from 'classnames'
import { motion } from 'framer-motion'

const draw = {
  hidden: {
    pathLength: 0,
    opacity: 0,
  },
  visible: {
    pathLength: 1,
    opacity: 1,
    transition: {
      pathLength: { delay: 0.2, type: 'spring', duration: 0.5, bounce: 0 },
      opacity: { delay: 0.2, duration: 0.01 },
    },
  },
}

export const Checkmark = ({
  isCompleted,
  className,
}: {
  isCompleted: boolean
  className?: string
}) => {
  return (
    <motion.svg
      className={cx(className)}
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      initial="hidden"
      animate={isCompleted ? 'visible' : 'hidden'}
      aria-hidden="true"
    >
      <motion.path
        variants={draw}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5 13l4 4L19 7"
      />
    </motion.svg>
  )
}
