import { FC } from 'react'
import * as Tooltip from '@radix-ui/react-tooltip'
import cx from 'classnames'
import { Side } from '@radix-ui/popper/dist/index'

export const TooltipContent: FC<{ className?: string; side?: Side }> = ({
  children,
  className,
  side = 'bottom',
}) => {
  return (
    <Tooltip.Content
      side={side}
      alignOffset={-10}
      sideOffset={5}
      className={cx(className, 'rounded-sm bg-black px-16 py-8 shadow-sm')}
    >
      <p className="text-14 font-semibold text-white">{children}</p>
      <Tooltip.Arrow className="fill-black" offset={10} />
    </Tooltip.Content>
  )
}
