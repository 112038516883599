import cx from 'classnames'

import { Input } from 'ui'

import { numberToCurrency } from 'utils'

export type CurrencyInputProps = {
  id: string
  label?: string
  srLabel?: string
  helperText?: string
  setHelperTextAbsolute?: boolean
  value: number
  setValue: (value: number) => void
  className?: string
  labelForTracking?: string
  setCurrentInputForTracking?: (label: string) => void
  error?: boolean
  placeholder?: string
  required?: boolean
  disabled?: boolean
  hideLabel?: boolean
  onBlur?: () => void
}

export const CurrencyInput = ({
  id,
  label,
  srLabel,
  value,
  setValue,
  helperText,
  setHelperTextAbsolute,
  className,
  labelForTracking,
  setCurrentInputForTracking,
  error,
  placeholder,
  required,
  disabled,
  hideLabel,
  onBlur,
}: CurrencyInputProps) => {
  const getValueWithDigitsOnly = (value: string): number =>
    Number(value.replace(/\D/g, ''))

  labelForTracking = labelForTracking ?? label

  return (
    <Input
      id={id}
      label={label}
      hideLabel={hideLabel}
      srLabel={srLabel}
      value={value ? numberToCurrency(value).slice(1) : ''}
      onChange={(e: React.FormEvent<HTMLInputElement>) => {
        setValue(getValueWithDigitsOnly(e.currentTarget.value))
        if (setCurrentInputForTracking && labelForTracking) {
          setCurrentInputForTracking(labelForTracking)
        }
      }}
      helperText={helperText}
      setHelperTextAbsolute={setHelperTextAbsolute}
      className={className}
      inputClassName="pl-40 border-0"
      inputWrapperClassName={cx(
        'relative ring-1',
        'before:absolute before:left-0 before:inset-y-0',
        'before:rounded-l-sm before:w-32 before:border-r',
        `before:content-['$'] before:grid before:place-items-center`,
        'rounded-sm',
        'focus-within:ring-2 focus-within:ring-teal-3',
        {
          'before:text-grey-5 before:border-grey-6 ring-grey-6': !error,
          'ring-error-1 before:text-error-1 before:border-error-1 focus-within:before:text-grey-5':
            error,
          'before:bg-grey-2': !disabled,
        }
      )}
      inputMode="numeric"
      error={error}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      removeFocusStyles
      onBlur={onBlur}
    />
  )
}
