import { FC } from 'react'
import cx from 'classnames'

export const HelperText: FC<{ error?: boolean; className?: string }> = ({
  children,
  className,
  error,
}) => {
  return (
    <p
      className={cx(className, 'text-12 tracking-1', {
        'text-grey-6': !error,
        'text-error-1': error,
      })}
    >
      {children}
    </p>
  )
}
